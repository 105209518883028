<template>
  <master-layout :showContentHeader="false" :showHeader="false">
    <ion-grid>
      <ion-row>
        <ion-col
          @click="closeModal"
          class="center"
          style="font-size: 1.75rem"
          size="2"
          ><ion-icon :icon="arrowBackOutline"></ion-icon
        ></ion-col>
        <ion-col class="center" size="8">
          <p><strong>Dettaglio Prodotto</strong></p>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-row>
            <ion-col>
              <img :src="item.pic" alt="" />
            </ion-col>
          </ion-row>

          <ion-row class="ion-margin-top">
            <ion-col class="show-promo">
              <div
                class="clip-path"
                :class="{ 'promo-activated': counter >= item.promo }"
              >
                PROMO
              </div>
              <div
                class="clip-path"
                :class="{ 'promo-activated': counter >= item.promo }"
              >
                {{ item.promo }} + 1 GRATIS
              </div>
            </ion-col>
          </ion-row>
          <ion-row class="ion-padding-horizontal">
            <ion-col>
              <p>
                <strong>{{ item.name }}</strong>
              </p>
            </ion-col>
            <ion-col>
              <p>
                <strong>€ {{ item.price.toFixed(2) }}</strong>
              </p>
            </ion-col>
          </ion-row>
          <ion-row class="ion-padding-horizontal ion-margin-bottom">
            <ion-col>
              {{ item.description }}
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="auto" class="counter">
              <ion-col @click="counter--"> - </ion-col>
              <ion-col>
                {{ counter }}
              </ion-col>
              <ion-col @click="counter++"> + </ion-col>
            </ion-col>
            <ion-col>
              <ion-button @click="closeModal"> Aggiungi</ion-button>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
    </ion-grid>
  </master-layout>

  <!-- <ion-button expand="block" @click="closeModal"> Close</ion-button> -->
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonSelect,
  IonSelectOption,
  modalController,
  IonIcon,
} from "@ionic/vue"
import { arrowBackOutline } from "ionicons/icons"
import DettaglioOrdine from "./DettaglioOrdine.vue"
export default {
  name: "singleItem",
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonSelect,
    IonSelectOption,
    modalController,
    IonIcon,
  },
  props: {
    item: Object,
  },
  data() {
    return {
      arrowBackOutline,
      counter: 0,
      clienteSelezionato: "",
      aziende: [
        {
          id: 1,
          name: "Mario",
          surname: "Pagano",
          pIva: 83094275483,
          telefono: "389 45611311",
        },
        {
          id: 2,
          name: "Giorgia",
          surname: "De Luca",
          pIva: 30489263483,
          telefono: "333 38381625",
        },
        {
          id: 3,
          name: "Alice",
          surname: "Rizzo",
          pIva: 12345678912,
          telefono: "334 23877723",
        },
        {
          id: 4,
          name: "Lorenzo",
          surname: "Barbieri",
          pIva: 21987654321,
          telefono: "324 34439284",
        },
        {
          id: 5,
          name: "Andrea",
          surname: "Villa",
          pIva: 12098744738,
          telefono: "368 34923900",
        },
      ],
    }
  },
  methods: {
    closeModal() {
      modalController.dismiss()
    },
    dettaglioOrdine() {
      // modalController.dismiss();
      this.openModal(this.item, this.counter)
    },
    async openModal(item, counter) {
      let cliente = {}
      this.aziende.forEach((element) => {
        if (this.clienteSelezionato.includes(element.name)) {
          cliente = JSON.parse(JSON.stringify(element))
        }
      })

      const modal = await modalController.create({
        component: DettaglioOrdine,
        cssClass: "my-custom-class",
        componentProps: {
          item: item,
          counter: counter,
          cliente: cliente,
        },
      })
      return modal.present()
    },
  },
}
</script>

<style scoped>
/* * {
  border: 1px solid pink;
} */

.show-promo {
  display: flex;
}

.clip-path {
  clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);

  padding: 0.5rem 2rem;
  background: var(--ion-color-secondary);
  color: var(--ion-color-primary);
  font-weight: bolder;
  text-align: center;
}

.counter {
  display: flex;
  margin: 0.5rem;
  border: 1px solid var(--text-color-light);
}

.counter ion-col {
  display: grid;
  place-items: center;
  padding: 0 1rem;
}

.promo-activated {
  background: var(--ion-color-primary);
  color: var(--ion-color-secondary);
}

.center {
  display: grid;
  place-items: center;
}
</style>
